<template>
  <div class="container-fluid" v-html="html">
    
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    AppCollapse,
    AppCollapseItem,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            arr: {},
            titles: {},
            done: false,
            saving: false,
            html: "",
            href: window.location.href.split('/weeklyplan-pdf/')[0],
            school_id: window.location.href.split("weeklyplan-pdf/")[1].split("/")[0],
            classroom: decodeURIComponent(window.location.href.split("weeklyplan-pdf/")[1].split("/")[1]),
            noshow: false,
            days: ["الاحد",'الاثنين','الثلاثاء','الاربعاء','الخميس']
        }
    },
    created(){
        var g = this;
        $.post(api + "/api/reports/parent/weeklyplan-pdf/get", {
            school_id: g.school_id,
            classroom: g.classroom
        }).then(function(r){
            if(r.status != 100){
                g.noshow = r.response;
                return;
            }
            g.done = true;
            g.arr = r.response;
            var days = [
                "الأحد",
                "الاثنين",
                "الثلاثاء",
                "الاربعاء",
                "الخميس",
            ]
            for (const [key, value] of Object.entries(r.response.arr)) {
                var body = "";
                for(var i=0; i<=4; i++){
                    if(value.filter(function(xx){
                        if(xx.DayNumber == i){
                            return xx;
                        }else{
                            return false;
                        }
                    }).length > 0){
                    body = body + `
                    <table style='width: 100%; text-align: right;'>
                        <thead>
                            <th style='background: #90C7D4; text-align: right; padding: 10px'><strong style='color: black; text-align: right'>يوم ${days[i]}</strong></th>
                        </thead>
                        </table>
                        <table style='width: 100%; text-align: right;'>
                    `
                        value.forEach(function(a){
                            if(a.DayNumber == i){
                                var videos = "";
                                if(a.videos){
                                    a.videos.forEach(function(video){
                                        videos = videos + `
                                        <a target='_blank' href='https://youtu.be/${video}'><img src='https://www.clipartbest.com/cliparts/9c4/6M4/9c46M4bBi.png' style='width: 30px'></a>
                                        `
                                    })
                                }
                                body = body + `
                                <tr style='background: #BAE5F5'>
                                    <td style='  text-align: right;'>الدرس</td>
                                    <td style=' text-align: right; color: black'>${a.Title} ${a.url ? "<u style='color: red !important; background: lightblue'><a style='color: red' href='"+a.url+"' target='_blank'>> مشاهدة الدرس</a></u>" : ''}</td>
                                </tr>
                                <tr style='background: #BAE5F5'>
                                    <td style='  text-align: right;'>الاهداف</td>
                                    <td style=' text-align: right; color: black'>${a.goal}</td>
                                </tr>
                                <tr style='background: #BAE5F5'>
                                    <td style='  text-align: right;'>الواجبات</td>
                                    <td style=' text-align: right; color: black'>${a.homeworks == '' ? "<span style='color: red'>لا يوجد واجب</span>" : a.homeworks}</td>
                                </tr>
                                <tr style='background: #BAE5F5'>
                                    <td style='  text-align: right;'>روابط تعليمية</td>
                                    <td style=' text-align: right; color: black'>
                                    ${videos}
                                    </td>
                                </tr>

                                <tr><td></td><td></td></tr>
                                `
                            }
                        })
                        body = body + `</table>`
                    }else{
                        body = body + ``
                    }
                }
                g.html = g.html + `
                <style>html, body {
                    height: 100%;
                    margin: 0;
                    backgrond: #BCBDBD
                }
                </style>
                <div class='page' style="background: #eee !important; page-break-after:always;">
                    <table style='width: 100%'>
                        <thead>
                            <th style='width: 30%'>
                            <img src="https://cdn-static.brmja.com/storage/scrapped/6319866ea8712498504411.jpg"
                                    style='width: 100px' alt="">
                            <br>
                            وزارة التعليم
                            <br>
                            ${value[0]?.SchoolName}
                            </th>
                            <th style='width: 30%'>
                                <img src='https://mhtwyat.com/wp-content/uploads/2020/11/%D8%B5%D9%88%D8%B1-%D8%B4%D8%B9%D8%A7%D8%B1-%D9%88%D8%B2%D8%A7%D8%B1%D8%A9-%D8%A7%D9%84%D8%AA%D8%B9%D9%84%D9%8A%D9%85-%D9%85%D8%B9-%D8%A7%D9%84%D8%B1%D8%A4%D9%8A%D8%A9-%D8%B4%D9%81%D8%A7%D9%81.png' style='width: 100px'>
                            </th>
                            <th style='width: 30%'>
                                الفصل: ${g.classroom}<br>
                                الاسبوع: ${g.arr.week}<br>
                                معلم المادة: ${value[0]?.teacher}
                            </th>
                        </thead>
                    </table>
                    <hr>
                    <div style='text-align: center; padding: 3px'><strong style='text-align: center; color: green; font-size: 16px'>
                        الخطة الاسبوعية لمادة <span style='color: #FF6600'>${key} </span>
                    </strong></div>
                    ${body}
                    
                </div>
                `
            }
        }).fail(function(){
        g.noshow = "حدث خطأ في الاتصال";
        return;})
    },
    methods: {
    }
}
</script>

<style>
#date2{
    display: none;
}
th,td{
    text-align: center;
}
[aria-expanded]:hover{
    background: #eee !important;
}
body{
    background: #fafafa !important;
}
@media only screen and (max-device-width: 640px) {
 
    td { white-space: nowrap !important; }
}
</style>